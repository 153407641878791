<script setup lang="ts">
import { useCompanyStore } from "@/store/company";

interface Emits {
  (e: "nextStep"): void;
  (e: "showNext"): void;
}

const emit = defineEmits<Emits>();
const { withLoader, isLoading } = useLoading();
const companyStore = useCompanyStore();
const { company } = storeToRefs(companyStore);

const claimFreeCredit = withLoader(async () => {
  try {
    if (company.value!.promo_claimed) throw new Error(ERROR_MESSAGES.promo_already_claimed);
    companyStore.claimFreeCredit();
    company.value!.promo_claimed = true;
    fireDefaultConfetti();
    emit("showNext");
  } catch (error) {
    showNotifyError((error as Error).message);
  }
});
</script>

<template>
  <div class="w-full h-full flex items-center justify-center">
    <transition name="slide-fade" mode="out-in">
      <PrimaryButton v-if="!company?.promo_claimed" secondary @click="claimFreeCredit" :disabled="isLoading" :spinner="isLoading"
        >Claim Free Credits</PrimaryButton
      >
      <PrimaryButton v-else secondary disabled>Claimed!</PrimaryButton>
    </transition>
  </div>
</template>
